body {
  margin: 0;
  font-family: 'AmazeSofiaProV7', 'helvetica neue', helvetica, arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: #ececec;
  background-color: #131416;

}

* {
  /* outline: 1px pink solid; */
  box-sizing: border-box;
}
